<template>
  <v-container v-if="profileType == 'Admin'">
    <v-layout class="item-title">
      <v-btn icon @click="onBack"><v-icon>mdi-chevron-left</v-icon></v-btn>
      Intake Questions
    </v-layout>
    <IntakeQuestions />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EditIntake",
  components: {
    IntakeQuestions: () => import("../components/intake/IntakeQuestions"),
  },
  methods: {
    onBack() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters("auth", {
      profileType: "getType",
    }),
  },
};
</script>

<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
}
</style>
